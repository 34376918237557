import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Flex, Box, Button, Heading, Text } from 'theme-ui'

const styles = {
  wrapper: src => ({
    alignItems: `center`,
    flexDirection: [`column`, `row`],
    bg: `black`,
    backgroundImage: src && [`none`, `none`, `url(${src})`],
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `70% 60%`,
    p: [2,4],
    size: [`full`],
    overflowX: `hidden`,
  }),
  left: {
    flexBasis: [`full`, `2/3`],
    textAlign: `center`
  },
  right: {
    flexBasis: [`full`, `1/3`],
    textAlign: `right`,
    mt:[3,0]
  },
  heading: {
    color: `white`,
    fontWeight: `normal`,
    maxWidth:`450px`
  },
  subheading: {
    color: `omega`,
    mb: [3, 0],
    maxWidth:`450px`
  }
}

const BannerHorizontal = (props) => {
  const data = useStaticQuery(bannerHorizontalQuery)
  const { file } = data

  const countdown = file.countdown && file.countdown.regular

  return (
    <Flex sx={styles.wrapper(props.picture && props.picture.fluid.src)}>
      <Box sx={styles.left}>
        <Heading variant='h3' sx={styles.heading}>
          {props.title}
        </Heading>
        <Text sx={styles.subheading}>{props.description}</Text>
      </Box>
      <Box sx={styles.right}>
        <Button as={Link} to={props.link} variant='primary' aria-label={props.buttonTitle}>
          {props.buttonTitle}
        </Button>
      </Box>
    </Flex>
  )
}

const bannerHorizontalQuery = graphql`
  query BannerHorizontalQuery {
    file(absolutePath: { regex: "/countdown.(jpeg|jpg|gif|png)/" }) {
      countdown: childImageSharp {
        regular: resize(width: 300, height: 150, cropFocus: CENTER) {
          src
        }
      }
    }
  }
`

export default BannerHorizontal
