import React from 'react'
import { css } from 'theme-ui'
import { Text, Heading, Box, Flex } from 'theme-ui'
import NewsletterForm from '@components/NewsletterForm'
import useSGAutorepondeur from '@helpers/useSGAutorepondeur'
import { FaRegPaperPlane, FaWind } from 'react-icons/fa'
import Img from 'gatsby-image'

const styles = {
  wrapper: {
    maxWidth: 600,
    p: 4,
    background: `rgba(0,0,0,0.32)`,
    borderRadius: `10px`
  },
  h1: {
    fontSize: `2.5em`,
    color:`white`
  },
  h2: {
    fontSize: `1.4em`,
    color:`white`
  },
  icons: {
    display: [`none`, `none`, `block`],
    position: `absolute`,
    top: `-3rem`,
    left: `5rem`,
    svg: {
      display: `block`
    }
  },
  plane: {
    fontSize: `9rem`,
    color: `beta`
  },
  wind: {
    fontSize: `7rem`,
    color: `omegaLight`,
    transform: `rotate(120deg)`,
    mt: `0.5rem`,
    ml: `-3rem`
  },
  form: {
    maxWidth: 300,
    // mx: `auto`,
    mt: 4,
    mr: 4,
    py: 4
  }
}

const NewsletterExpanded = ({ newsletterInfo, simple }) => {
  const {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success,
  } = useSGAutorepondeur()

  return (
      <Box sx={styles.wrapper}>
        {!simple && (
          <Box sx={styles.icons}>
            <FaRegPaperPlane css={css(styles.plane)} />
            <FaWind css={css(styles.wind)} />
          </Box>
        )}
        <Heading variant='h2' sx={styles.h1}>{newsletterInfo.headerTitle}</Heading>
        <Text variant='p' sx={styles.h2}>
          {newsletterInfo.description}
        </Text>
        <Flex>
          <Box sx={styles.form}>
            <NewsletterForm
              {...{
                handleSubmit,
                canSubmit,
                submitting,
                message,
                success
              }}
              buttonTitle={newsletterInfo.buttonTitle}
            />
          </Box>
          <Box sx={{width:`50%`, display:[`none`, `block`]}}>
            <Img 
              fluid={newsletterInfo.picture.fluid} 
              loading="lazy" 
              alt={newsletterInfo.picture.title} 
              css={css({height: `100%`})}
            />
          </Box>
        </Flex>
      </Box>
  )
}

NewsletterExpanded.defaultProps = {
  simple: false
}

export default NewsletterExpanded
