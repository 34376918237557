import { graphql } from 'gatsby'
import PostsPage from '../containers/Posts'

export default PostsPage

export const pageQuery = graphql`
  query PostsPageQuery($paginatePostsPage: Boolean!, $skip: Int, $limit: Int) {
    featuredPosts: allArticle(
      filter: { private: { ne: true }, featured: { eq: true } }
      sort: { fields: [date], order: DESC }
      limit: 10
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailFeatured
      }
    }

    salesPages: allContentfulSalesPage(
      limit: 10
      filter: { private: { ne: true }}
    ) {
      edges {
        node {
          id
          headerTitle
          slug
          title
          shortDescription
          minimumWallet
          context
          timeToSpend
          picture {
            title
            fixed(width: 400) {
              src
              srcSet
              srcWebp
              srcSetWebp
              width
              height
            }
          }
        }
      }
      totalCount
    }

    recentPosts: allArticle(
      filter: { private: { ne: true } }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }

    landingPageInfo: contentfulLandingPage {
      id
      mainHeader
      mainDescription
      author{
        name
        description{
          description
        }
        descriptionRich{
          raw
        }
        thumbnail{
          title
          fluid(maxWidth: 800, quality: 100) {
            src
          }
        }
      }
      contentBox1Title
      contentBox2Title
      contentBox3Title
      contentBox1Link
      contentBox2Link
      contentBox3Link
      headerImage{
        fluid (maxWidth: 1500){
          src
        }
      }
      contentBox1Description {
        raw
      }
      contentBox2Description {
        raw
      }
      contentBox3Description {
        raw
      }
      contentBox1Icon {
        title
        fixed (width:50) {
          src
        }
      }
      contentBox2Icon {
        title
        fixed (width:50) {
          src
        }
      }
      contentBox3Icon {
        title
        fixed (width:50) {
          src
        }
      }
      exitIntentModal {
        header
        content{
          raw
        }
        newsletterBox{
          buttonTitle
          description
          headerTitle
          picture {
            fluid(maxWidth: 900) {
              src
            }
          }
        }
      }
      entranceModal {
        header
        content{
          raw
        }
        newsletterBox{
          buttonTitle
          description
          headerTitle
          picture {
            fluid(maxWidth: 900) {
              src
            }
          }
        }
      }
      newsletterBox{
        buttonTitle
        description
        headerTitle
        picture {
          title
          fluid(maxWidth: 300) {
            src
          }
        }
      }
    }

    partner: allContentfulPartner(limit: 1) {
      edges {
        node {
          id
          link
          title
          description
          buttonTitle
          picture {
            fluid (maxWidth: 300) {
              src
            }
          }
        }
      }
    }

    posts: allArticle(
      filter: { private: { ne: true }, category: {showLanding: {eq: true}}}
      sort: { fields: [date], order: DESC }
      limit: 1000
    ) @skip(if: $paginatePostsPage) {
      group(field: category___name, limit: 10) {
        categoryName: fieldValue
        nodes {
          ...ArticlePreview
          ...ArticleThumbnailRegular
        }
      }
      totalCount
    }

    paginatedPosts: allArticle(
      filter: { private: { ne: true } }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) @include(if: $paginatePostsPage) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
      ...ArticlePagination
    }
  }
`
