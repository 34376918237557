import React from 'react'
import { Flex, Heading, Box } from 'theme-ui'
import Section from '@components/Section'

const styles = {
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `none`,
    size: [`full`, `2/3`],
    width: `auto`,
    p: 4,
    m: [3,2],
    flex: 1,
    minWidth: [`2/3`, `auto`],
  },
  heading: {
    fontSize: `1.3em`,
    color: `black`,
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 2,
    },
  },
  subheading: {
    color: `black`,
    fontWeight: `normal`,
   lign:`justify`
  },
  list: {
    color: `black`,
    listStyle: `none`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2,
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`,
    },
  },
  button: {
    display: `block`,
    mt: `auto`,
  },
  horizontal: {
    display: `flex`,
    textAlign: `center`,
    justifyContent: [`flex-start`, `space-evenly`],
    flexWrap: `wrap`,
    width: `auto`,
    m: -2
  },
  horizontal2: {
    textAlign: `center`,
    justifyContent: [`flex-start`, `space-evenly`],
    flexWrap: `nowrap`,
    width: `auto`,
    m: -2
  },
  links: {
    textDecoration : `none`,
    color: `black`
  }
}

export default (props) => (
  <Section aside='horizontal'>
    <Flex sx={styles.horizontal}>
      <Box sx={styles.wrapper}>
        <Heading variant='h2' sx={styles.heading}>
          <div style={styles.horizontal2}>
            <a href={props.link1} target="_blank" style={styles.links} rel="noreferrer">
              <div><img src={props.icon1.fixed.src} alt={props.icon1.title} /></div>
              <div>{props.title1}</div>
            </a>
          </div>
        </Heading>
        <Heading variant='h4' sx={styles.subheading}>
          {props.description1}
        </Heading>
      </Box>
      <Box sx={styles.wrapper}>
        <Heading variant='h2' sx={styles.heading}>
          <div style={styles.horizontal2}>
            <a href={props.link2} target="_blank" style={styles.links} rel="noreferrer">
              <div><img src={props.icon2.fixed.src} alt={props.icon2.title} /></div>
              <div>{props.title2}</div>
            </a>
          </div>
        </Heading>
        <Heading variant='h4' sx={styles.subheading}>
          {props.description2}
        </Heading>
      </Box>
      <Box sx={styles.wrapper}>
        <Heading variant='h2' sx={styles.heading}>
          <div style={styles.horizontal2}>
            <a href={props.link3} target="_blank" style={styles.links} rel="noreferrer">
              <div><img src={props.icon3.fixed.src} alt={props.icon3.title} /></div>
              <div>{props.title3}</div>
            </a>
          </div>
        </Heading>
        <Heading variant='h4' sx={styles.subheading}>
          {props.description3}
        </Heading>
      </Box>
    </Flex>
  </Section>
)