import React, {useEffect,useState} from 'react'
import { Stack, Main} from '@layout'
import { Heading, Box, Flex } from 'theme-ui'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ExitIntent from '@widgets/ExitIntent'
import EntranceModal from '@components/EntranceModal'
import ExitIntentModal from '@components/ExitIntentModal'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import BannerHorizontal from '@widgets/BannerHorizontal'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { Link } from 'gatsby'

const styles = {
  parallax : {
    backgroundAttachment: `fixed`,
    backgroundPosition:`center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    minHeight: `600px`,
    maxWidth:`100%`
  },
  formations: {
    maxWidth: `100%`,
    background: `#F0F0F0`,
    py: 5
  },
  articles: {
    maxWidth: `100%`,
    py: 5
  },
  partner:{
    maxWidth: `100%`,
    background: `black`,
    py: 3
  },
  horizontal: {
    display: `flex`,
    textAlign: `center`,
    justifyContent: [`flex-start`, `space-evenly`],
    flexWrap: `wrap`,
    overflowX: `auto`,
    width: `auto`,
    scrollBehavior: `smooth`,
    m: -2
  },
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `none`,
    borderRadius: `lg`,
    size: [`full`, `1/3`],
    p:1,
    m: [1,3],
    flex: 1,
    minWidth: [`2/3`, `auto`],
  },
  wrapper2: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `none`,
    size: [`full`, `2/3`],
    p: 1,
    m: [2,0]
  },
  authorDescription : {
    color: `black`,
    fontSize: `1.1em`,
    textAlign: `left`,
    paddingTop:`1rem`

  }
}

const Posts = ({ data: { posts = {}, featuredPosts = {}, partner = {}, landingPageInfo ={}, salesPages = {} }, ...props }) => {
  const { pageContext: { services = {}, siteUrl } = {} } = props

  //Get partner info
  const partnerInfo1 = partner.edges.length > 0 && partner.edges[0].node
  const partnerInfo2 = partner.edges.length > 1 && partner.edges[1].node

  const salesPagesInfo = salesPages.edges.map(node => {
      return node.node
  })

  const [showPopupExit, setShowPopupExit] = useState(false)
  const [showPopupEntrance, setShowPopupEntrance] = useState(false)

  //Dispaly modal exit 
  useEffect(() => {
    if (document.cookie.indexOf("modal_exit_seen=true") < 0) {
      const removeExitIntent = ExitIntent({
        threshold: 30,
        eventThrottle: 100,
        onExitIntent: () => {
          setShowPopupExit(true)
        },
      })
      return () => {
        removeExitIntent()
      }
    }
  })

  //Display modal entrance
  useEffect(() => {
    if (document.cookie.indexOf("modal_entrance_seen=true") < 0) 
    {
      const interval = setInterval(() => {
        setShowPopupEntrance(true)
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [])

  const backgroundImage = landingPageInfo.headerImage ? `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.3)), url(${landingPageInfo.headerImage.fluid.src})` : ''

  const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p style={{margin:0}}>{children}</p>,
    }
  };

  return (
    <>
      {landingPageInfo.exitIntentModal && <ExitIntentModal data={landingPageInfo.exitIntentModal} show={showPopupExit} />}
      {landingPageInfo.entranceModal && <EntranceModal data={landingPageInfo.entranceModal} show={showPopupEntrance} />}
      <Seo title='Indépendance financière, haut rendement et stratégie options' siteUrl={siteUrl}/>

      <Main sx={{...styles.parallax, backgroundImage: backgroundImage}}>
         <Stack>
            <Main sx={{py:`5rem`}}>
              <NewsletterExpanded newsletterInfo={landingPageInfo.newsletterBox} simple />
            </Main>
         </Stack>
      </Main>
      
      {/**<Stack>
        <Main>
          <Hero 
            title={landingPageInfo.mainHeader}
            description={landingPageInfo.mainDescription}
            picture={landingPageInfo.headerImage.fixed.src}
            numberArticles={posts.totalCount}
            numberSales={salesPages.totalCount}
          />
        </Main>
      </Stack>**/}
      <Divider />

      <Stack>
        <Main>
          <Box sx={{textAlign: `center`, marginBottom: `4rem`}}>
            <Heading as="h1" sx={{fontSize: `2.5em`}}>{landingPageInfo.mainHeader}</Heading>
          </Box>
          <ContentBlock 
            icon1={landingPageInfo.contentBox1Icon}
            icon2={landingPageInfo.contentBox2Icon}
            icon3={landingPageInfo.contentBox3Icon}
            title1={landingPageInfo.contentBox1Title}
            title2={landingPageInfo.contentBox2Title}
            title3={landingPageInfo.contentBox3Title}
            link1={landingPageInfo.contentBox1Link}
            link2={landingPageInfo.contentBox2Link}
            link3={landingPageInfo.contentBox3Link}
            description1 = {landingPageInfo.contentBox1Description && renderRichText(landingPageInfo.contentBox1Description)}
            description2 = {landingPageInfo.contentBox2Description && renderRichText(landingPageInfo.contentBox2Description)}
            description3 = {landingPageInfo.contentBox3Description && renderRichText(landingPageInfo.contentBox3Description)}
          />
        </Main>
      </Stack>

      <Stack>
        <Main sx={{my: `3rem`, p:`2`}}>
          <Box sx={{textAlign: `center`, marginBottom: `4rem`}}>
            <Heading as="h2" sx={{fontSize: `2.5em`}}>Qui suis-je?</Heading>
          </Box>
          <Flex sx={styles.horizontal}>
            <Box sx={styles.wrapper}>
              <Link href="/gael-deballe-formation-produits-derives">
                <img src={landingPageInfo.author.thumbnail.fluid.src} alt={landingPageInfo.author.thumbnail.title} />
              </Link>
            </Box>
            <Box sx={styles.wrapper2}>
              <div style={styles.authorDescription}>
                {renderRichText(landingPageInfo.author.descriptionRich, options)}
              </div>
            </Box>
          </Flex>
        </Main>
      </Stack>

      <Main sx={styles.partner}>
        <Stack effectProps={{ effect: 'none' }}>
          <Main>
            <BannerHorizontal 
              title={partnerInfo1.title}
              link={partnerInfo1.link}
              description={partnerInfo1.description}
              picture={partnerInfo1.picture}
              buttonTitle={partnerInfo1.buttonTitle}
            />          
          </Main>
        </Stack>
      </Main>

      <Main sx={styles.formations}>
        <Stack>
          <Main>
            <CardList
              nodes={salesPagesInfo}
              limit={15}
              variant='vertical'
              title='Mes formations sur options'
              columns={[1, 2, 2, 2]}
            />
          </Main>
        </Stack>
      </Main>
     
      <Main sx={styles.articles}>
        <Stack >
          {/** Show Posts and Partner boxes **/}
          <Main>
                <CardList
                  nodes={featuredPosts.nodes}
                  limit={6}
                  variant='horizontal'
                  title="Nos meilleurs articles"
                  columns={[1, 2, 2, 2]}
                  omitAuthor
                  omitFooter
                  titleLinkUrl='auteur/gael-deballe/'
                />
          </Main>
        </Stack>
      </Main>
    </>
  )
}

export default Posts
